import {
  MainCore,
  game,
  timeManager,
  CameraTypes,
  settings,
  SettingsTypes,
  gameStats,
  CustomEvents,
  THREE,
  AudioManager,
  CameraStates,
  cameraManager,
  requestManager,
  TimesTypes,
  fpsManager,
  appWSM2021Config,
  modes,
  AppWSM2021DifficultyTypes,
  playersConfig,
  corePhasesManager,
  PlayersSortTypes,
  PlayersResultTypes,
  playersManager,
  tutorialManager,
  CorePhases,
  vibrations
} from '@powerplay/core-minigames'
import {
  audioConfig,
  batchingConfig,
  modelsConfig,
  texturesConfig,
  debugConfig,
  cameraConfig,
  gameConfig,
  trainingConfig,
  translatesReplacements,
  sponsorsConfig
} from './config'
import {
  MaterialsNames,
  type SpecialDataFromInit,
  TexturesNames
} from './types'
import {
  debugState,
  trainingState,
  tutorialState
} from '@/stores'
import { worldEnv } from './entities/env/WorldEnv'
import { player } from './entities/athlete/player'
import { pigeon } from './entities/equipment/Pigeon'
import { inputsManager } from './InputsManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { appWSM2021AllDifficultiesConfig } from './config/appWSM2021AllDifficultiesConfig'
import { materialsConfig } from './config/materialsConfig'
import { trainingTasks } from './modes/training/TrainingTasks'
import { stateManager } from './StateManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { pathAssets } from '@/globals/globalvariables'
import { timeLimitManager } from './TimeLimitManager'
import * as Sentry from '@sentry/vue'
import { opponentsManager } from './entities/athlete/opponent/OpponentsManager'
import { aimingDirectionManager } from '@/app/phases/AimPhase/AimingDirectionManager'
import { particleEffects } from './ParticleEffects'
import { loadingState } from '@powerplay/core-minigames-ui-ssm'

/**
 * Hlavna trieda pre disciplinu
 */
export class Main {

  /** Hlavna trieda z CORE */
  private mainCore: MainCore

  // Aktualna pozicia kamery
  private actualCameraPosition = new THREE.Vector3()

  // Aktualna rotacia kamery
  private actualCameraQuaternion = new THREE.Quaternion()

  /** Pause prveho tutorialu */
  private pausedFirstTutorial = false

  /** Clock timer */
  private clock = new THREE.Clock()

  /**
   * Konstruktor
   */
  public constructor() {

    this.addListeners()
    // pripravenie konfigov pre WSM 2021 - musime kontrolvat takto kvoli typescriptu
    if (modes.isAppWSM2021()) {

      appWSM2021Config.data = appWSM2021AllDifficultiesConfig[
        modes.mode as unknown as AppWSM2021DifficultyTypes // small TS hack :)
      ]

    }

    /*
     * Nastavenie players konfigov, aby sa dobre zoradovali a mali dobre empty vysledky
     * nemusime zatial nic nastavovat, lebo berieme default hodnoty z konfigu
     */
    this.setPlayersConfig()

    /**
     * Nastavenie specialnych sponzorov
     */
    this.setSpecialSponsor()

    // musime este nastavit, aby nebolo intro v treningu
    corePhasesManager.showIntro = !modes.isTrainingMode()

    // spustime CORE veci a pokial vsetko je v pohode, pusti sa INIT metoda
    this.mainCore = new MainCore(
      {
        meshesCastShadows: materialsConfig[MaterialsNames.athlete].meshesArray || [],
        meshesAnisotropy: ['Trap_Mesh_17'],
        materials: materialsConfig,
        callbacks: {
          inputs: {
            callbackLeft: inputsManager.onKeyLeft,
            callbackRight: inputsManager.onKeyRight,
            callbackUp: inputsManager.onKeyUp,
            callbackDown: inputsManager.onKeyDown,
            callbackAction: inputsManager.onKeyAction,
            callbackAction2: inputsManager.onKeyAction2,
            callbackAction3: inputsManager.onKeyAction3,
            callbackExit: inputsManager.onKeyExit,
            callbackPrepareVideo: inputsManager.onKeyPrepareVideo
          },
          setSpecialDataFromInitRequest: this.setSpecialDataFromInitRequest,
          createAssets: this.createAssets,
          beforeGameStart: this.beforeGameStart,
          updateBeforePhysics: this.updateBeforePhysics,
          updateAfterPhysics: this.updateAfterPhysics,
          updateAnimations: this.updateAnimations
        },
        batching: batchingConfig,
        debugConfig,
        numberOfAttempts: modes.isTrainingMode() ? gameConfig.numberOfAttemptsTraining : gameConfig.numberOfAttempts,
        inputSchema: 'biathlon'
      },
      translatesReplacements,
      {
        textures: texturesConfig,
        models: modelsConfig,
        audio: audioConfig
      },
      aimingDirectionManager.onUpdateAimSensitivity,
      () => {
        // onUpdateOneFinger - momentalne to tu musi byt, aby sa riesilo posielanie hodnoty nastavenia na server
      }
    )

    this.initialSetup()

  }

  /**
   * Nastavenie specialneho sponzora
   */
  private setSpecialSponsor(): void {

    const timeNow = Date.now()
    const random = THREE.MathUtils.randFloat(0, 1)
    const sponsorBannerToShow = {
      name: '',
      percent: 0,
      textureName: '',
      textureNameScreens: '',
    }

    // zatial kontrolujeme iba bannery
    sponsorsConfig.banners.forEach((sponsor) => {

      if (!sponsor.active) return

      // sponsor.name
      sponsor.times.forEach((slot) => {

        if (slot.from <= timeNow && slot.to > timeNow && random < slot.percent) {

          sponsorBannerToShow.name = sponsor.name
          sponsorBannerToShow.percent = slot.percent
          sponsorBannerToShow.textureName = sponsor.textureName
          sponsorBannerToShow.textureNameScreens = sponsor.textureNameScreens
          console.log('vyberame sponzora pre bannery: ', sponsorBannerToShow)

        }

      })

    })

    // ak je nejaky sponzor - vyberie sa posledny
    if (sponsorBannerToShow.name !== '') {

      // zmenime assety - vymazeme povodny a dame novy
      delete texturesConfig[TexturesNames.colorGradient]
      delete texturesConfig[TexturesNames.screens]
      materialsConfig[MaterialsNames.colorGradient].textureName = sponsorBannerToShow.textureName
      materialsConfig[MaterialsNames.screens].textureName = sponsorBannerToShow.textureNameScreens

    } else {

      // tu dame vsetky specialne
      delete texturesConfig[TexturesNames.colorGradientVshosting]
      delete texturesConfig[TexturesNames.screensVshosting]

    }

  }

  /**
   * Metoda na overenie a zobrazenie FPS
   */
  private checkFpsRequest(): void {

    if (stateManager.getFpsStarted()) {

      const settingsQuality = settings.getSetting(SettingsTypes.quality)
      const fpsData = {
        averageFps: fpsManager.getAverageFpsByQuality(settingsQuality),
        actualFps: fpsManager.getActualFpsByQuality(settingsQuality),
        actualAverageFps: fpsManager.getActualAverageFps()
      }
      stateManager.setFpsData(fpsData)

    }

  }

  /**
   * Pridanie listenerov
   */
  private addListeners() {

    window.addEventListener(CustomEvents.readyForDisciplineInit, this.init)
    window.addEventListener(CustomEvents.loadingProgress, this.loadingProgress)
    // nastavime pocuvanie na zaciatok disciplinovej fazy z CORE
    window.addEventListener(
      CustomEvents.startDisciplinePhase,
      disciplinePhasesManager.setStartPhase
    )

  }

  /**
   * Inicializacny setup
   */
  private initialSetup() {

    const localEnv = Number(import.meta.env.VITE_APP_LOCAL) === 1
    this.mainCore.setIsLocalEnv(localEnv)
    game.setIsLocalEnv(localEnv)
    game.setCallbackCustomUpdateQuality(this.onUpdateQuality)

    // lokalne si davame ID discipliny, aby sme nemuseli menit v GET parametroch stale
    if (localEnv) requestManager.disciplineID = 13

    // zakazeme fazu provisional results
    corePhasesManager.provisionalResultsAllowed = !(modes.isTutorial() || modes.isTrainingMode())
    corePhasesManager.provisionalResultsFrequency = 5

    AudioManager.PATH_ASSETS = pathAssets

    vibrations.checkAvailability()

    /*
     * listener na zistenie appky, ze sme v background mode a mame dat pauzu, aby sme setrili
     * prostriedky a aby nehrali zvuky
     */
    window.addEventListener(CustomEvents.toggleBackgroundMode, () => {

      tutorialState().settings = true

    }, false)

  }

  /**
   * Vratenie ignorovanych nazvov textur
   * @returns Pole nazvov ignorovanych textur
   */
  private getIgnoredTexturesNames(): string[] {

    const allRaceTextures = [
      TexturesNames.athleteRaceBlackMan,
      TexturesNames.athleteRaceBlackWoman,
      TexturesNames.athleteRaceMulattoMan,
      TexturesNames.athleteRaceMulattoWoman,
      TexturesNames.athleteRaceWhiteMan,
      TexturesNames.athleteRaceWhiteWoman
    ]

    const usedTextures: string[] = []

    // pridame hraca
    const playerInfo = playersManager.getPlayer()
    usedTextures.push(`${playerInfo.sex}/${TexturesNames.athleteRacePrefix}${playerInfo.race}`)

    // pridame superov, ak su
    opponentsManager.getOpponentsIds().forEach((uuid) => {

      const opponentInfo = playersManager.getPlayerById(uuid)
      usedTextures.push(`${opponentInfo?.sex}/${TexturesNames.athleteRacePrefix}${opponentInfo?.race}`)

    })

    // vysledok bude rozdiel dvoch poli
    return allRaceTextures.filter(x => !usedTextures.includes(x))

  }

  /**
   * Inicializacia main core
   */
  public init = (): void => {

    // musime nastavit prefix pre meshe, aby nam nekolidovali meshe zo skyboxu s inymi
    game.prefixMeshesGroupEnabled = true

    game.shadowsManager.setShadowPlaneResolution(30, 30)
    game.shadowsManager.setShadowCameraFrustumPlaneDimensions(7, 7, 7, 7)

    this.mainCore.init(
      undefined,
      undefined,
      undefined,
      undefined,
      opponentsManager.getOpponentsIds(),
      this.getIgnoredTexturesNames(),
      TexturesNames.athleteRacePrefix
    )
    disciplinePhasesManager.create(this.prepareGameForNextAttempt)

    const tweenSettingsForCameraStates = modes.isTrainingMode() ?
      trainingConfig.tweenSettingsForCameraStates :
      cameraConfig.tweenSettingsForCameraStates

    this.mainCore.setTweenSettingsForStates(tweenSettingsForCameraStates)

    cameraManager.changeBaseRenderSettings(0.1, 2000)
    cameraManager.minimalPositionY = 1
    trainingTasks.initTraining()
    timeManager.setActive(TimesTypes.total, true)

    // UI update
    stateManager.allowDirectionState()

  }

  /**
   * Nastavenie konfigu pre hracov
   */
  private setPlayersConfig(): void {

    playersConfig.sortType = PlayersSortTypes.descending
    playersConfig.resultType = PlayersResultTypes.points

  }

  /**
   * Zobrazenie progresu loadingu
   */
  private loadingProgress = (): void => {

    gameStats.setNextLoadingPart()
    loadingState().loadingProgress = gameStats.getLoadingProgress()

  }

  /**
   * Nastavenie specialnych dat z init requestu
   * @param data - Specialne data
   */
  private setSpecialDataFromInitRequest = (data: unknown): void => {

    const specialData = data as SpecialDataFromInit
    console.log(specialData)

    Sentry.setContext('minigame', {
      id: requestManager.MINIGAME_START_ID,
      idUser: player.uuid
    })

  }

  /**
   * Nastavenie assetov
   */
  private createAssets = (): void => {

    worldEnv.create()
    player.create()
    opponentsManager.create()
    const objectForClone = game.scene.getObjectByName('athlete_opponent')
    if (objectForClone) objectForClone.visible = false
    player.createTools()
    this.setUpDebug()

    // Partikle
    particleEffects.createEmitters()

  }

  /**
   * Ak sa zmeni kvalita, vykonaju sa nasledujuce veci, pre optimalizaciu performance
   * @param settingValue - Hodnota kvality
   */
  public onUpdateQuality = (settingValue: number): void => {

    // hybanie vlajok
    worldEnv.movingFlagsEnabled = settingValue >= 7

    // hybanie skyboxu
    worldEnv.skybox.movingEnabled = settingValue >= 5

  }

  /**
   * puts singletons into window object
   */
  private setUpDebug(): void {

    if (!Number(import.meta.env.VITE_APP_LOCAL)) return

    const debug = {
      worldEnv,
      inputsManager,
      player,
      disciplinePhasesManager,
      cameraManager: cameraManager,
      setHUDVisibility: () => (debugState().isHudActive = true),
      pauseGame: () => {

        if (game.paused) game.resumeGame()
        else game.pauseGame()

      },
      scene: game.scene,
      game,
      THREE,
      pigeon,
      opponentsManager,
      tutorialManager,
      aimingDirectionManager,
      playersManager,

      changeSun: (newValue: number) => {

        worldEnv.skybox.updateSunPosition(newValue)

      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).debug = debug

  }

  /**
   * Nastavenie alebo spustenie veci pred startom hry
   */
  private beforeGameStart = (): void => {

    stateManager.setBeforeGameStartPhase()
    playersManager.setCompareSkillBeforeTimestamp()

    // ked je zobrazeny renderer info, tak ho vieme takto zobrazit na mobiloch
    // game.rendererInfo.domElement.setAttribute(
    //   'style',
    //   'width:80px;opacity:0.9;cursor:pointer;position:fixed;right:0px;' +
    //   'bottom:00px;z-index: 100;transform: scale(1.5);transform-origin: right bottom;'
    // )

  }

  /**
   * Spustenie veci v update pred vykonanim fyziky
   */
  private updateBeforePhysics = (): void => {

    if (timeLimitManager.check()) return

    // wind.update()
    disciplinePhasesManager.update()
    player.updateBeforePhysics()
    opponentsManager.updateBeforePhysics()
    this.checkFpsRequest()

  }

  /**
   * Spustenie veci v update po vykonani fyziky
   */
  private updateAfterPhysics = (delta: number): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    if (requestManager.isFirstTrainingTutorial() && !this.pausedFirstTutorial) {

      loadingState().showLoading = false
      this.pausedFirstTutorial = true
      console.log(requestManager.TUTORIAL_ID)
      trainingState().firstTutorialMessage = true
      game.pauseGame()
      return

    }

    particleEffects.update()
    player.updateAfterPhysics()
    opponentsManager.updateAfterPhysics()

    if (!disciplinePhasesManager.phaseAim.lockedCameraMove) {

      if (disciplinePhasesManager.phaseAim.changeCameraToReplayNextCameraMove) {

        disciplinePhasesManager.phaseAim.changeCameraToReplay()

      }

      const playerPosition = disciplinePhasesManager.phaseAim.isReplay ?
        pigeon.pigeonBody.position.clone() :
        player.getPosition()
      const cameraQuaternion = disciplinePhasesManager.phaseAim.isReplay ?
        pigeon.pigeonBody.quaternion.clone() :
        player.getQuaternion()

      cameraManager.move(
        playerPosition,
        cameraQuaternion,
        delta,
        [/* hill.hillMesh */],
        cameraConfig.distanceFromGround,
        cameraManager.isThisCameraState(CameraStates.disciplineOutro),
        disciplinePhasesManager.phaseAim.isInEmotion || disciplinePhasesManager.phaseAim.isReplay ? undefined : {
          lerpCoef: 1,
          newQuat: player.getQuaternion().clone()
        },
        undefined,
        undefined,
        disciplinePhasesManager.phaseAim.isReplay ? 0 : undefined
      )

    }

    stateManager.setUpdateTimeState()

    if (modes.isTutorial()) tutorialFlow.update()

    worldEnv.update()

  }

  /**
   * Spustenie vykonavania vsetkych animacii
   * @param delta - Delta
   */
  private updateAnimations = (delta: number): void => {

    worldEnv.updateWithAnimations(this.clock.getElapsedTime())

    if (!corePhasesManager.isActivePhaseByType(CorePhases.discipline)) return

    player.updateAnimations(delta)
    opponentsManager.updateAnimations(delta)

  }

  /**
   * Zmena kamery na debug, ak by sme potrebvalo
   */
  public changeCameraToDebug = (): void => {

    cameraManager.setCamera(CameraTypes.debug)

  }

  /**
   * Pripravenie hry pre dalsi pokus
   */
  private prepareGameForNextAttempt = (): void => {

    disciplinePhasesManager.resetAttempt()

  }

}
