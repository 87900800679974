import {
  directionsState,
  speedmeterState,
  timeState
} from '@/stores'
// import { LoadingState } from '@powerplay/core-minigames-ui-ssm'
import {
  playersManager,
  settings,
  SettingsTypes,
  timeManager,
  type PlayerInfoForTable,
  corePhasesManager,
  modes,
  requestManager,
  CustomEvents,
  CorePhases,
  PhaseFinalResultsManager
} from '@powerplay/core-minigames'
import type { MiniTableRowData } from '@powerplay/core-minigames-ui-ssm/ssm-dist/src/ui/common/MiniTable/MiniTable.vue'
import { player } from '../entities/athlete/player'
import {
  fpsLookerState,
  gameSettingsState,
  waitingState,
} from '@powerplay/core-minigames-ui-ssm'
import { getActivePinia } from 'pinia'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'
import {
  gameConfig,
  shootingConfig
} from '../config'

export interface FpsDataVuex {
  averageFps: number
  actualFps: number
  actualAverageFps: number
}

export interface SpeedData {
  visible: boolean
  speed: string | number
}

export class StateManager {

  public getFpsStarted(): boolean {

    return fpsLookerState().isStarted ?? false

  }

  public setFpsData(fpsData: FpsDataVuex): void {

    fpsLookerState().$patch(fpsData)

  }

  public allowDirectionState(): void {

    directionsState().show = true

  }

  public setBeforeGameStartPhase(): void {

    gameSettingsState().$patch({
      graphicsSettings: settings.getSetting(SettingsTypes.quality),
      graphicsAuto: settings.getSetting(SettingsTypes.qualityAuto) === 1,
      volume: settings.getSetting(SettingsTypes.sounds) === 1,
      isLeft: settings.getSetting(SettingsTypes.isLeft) === 1,
      controlsType: settings.getSetting(SettingsTypes.controlsType),
      aimSensitivity: settings.getSetting(SettingsTypes.aimSensitivity),
      vibrations: settings.getSetting(SettingsTypes.vibrations) === 1,
      oneFinger: settings.getSetting(SettingsTypes.oneFinger) === 1,
    })

  }

  public setUpdateTimeState(): void {

    timeState().time = timeManager.getGameTimeInFormat(1)

  }

  public hideSpeedVisibility(): void {

    speedmeterState().visible = false

  }

  public updateSpeedData(speedData: SpeedData): void {

    speedmeterState().$patch({
      speed: Number(speedData.speed),
      visible: speedData.visible
    })

  }

  /**
   * Vytiahnutie dat pre minitable
   */
  public static getDataForMiniTable(playerPoints = 0) {

    const rowsData = [] as MiniTableRowData[]

    const standings = playersManager.getStandings()
    const playerStanding = standings.filter((value) => value.playable)[0]
    const playerPosition = playerStanding.position

    let indexes = []

    if (playerPosition < 4) {

      indexes = [0, 1, 2, 3]

    } else if (playerPosition === standings.length) {

      indexes = [0, playerPosition - 3, playerPosition - 2, playerPosition - 1]

    } else {

      indexes = [0, playerPosition - 2, playerPosition - 1, playerPosition]

    }

    if (
      disciplinePhasesManager.shootOutAttempt > 0 &&
      Number((playerStanding.resultsFull?.[gameConfig.numberOfAttempts - 1]?.second || 0)) !==
        disciplinePhasesManager.shootOutAttempt
    ) {

      indexes = [0, 1, 2, 3]

    }

    indexes.forEach((index) => {

      if (standings[index]) {

        const minitableRowData = this.getMiniTableDataFromPlayerInfo(standings[index], playerPoints)
        if (disciplinePhasesManager.shootOutAttempt > 0) {

          let time = 0
          const third = standings[index].resultsFull?.[gameConfig.numberOfAttempts - 1]?.third
          const second = standings[index].resultsFull?.[gameConfig.numberOfAttempts - 1]?.second

          if (third !== undefined && Number(second) === disciplinePhasesManager.shootOutAttempt) {

            time = shootingConfig.shootOutTimeBase - Number(third)

          }
          console.warn(standings[index].uuid, time)
          let result = time >= shootingConfig.shootOutTimeBase ?
            'MISS' :
            timeManager.getTimeInFormatFromSeconds(time)

          if (
            standings[index].playable &&
            disciplinePhasesManager.phaseAim.shotsFired < shootingConfig.shotsLimitShootout
          ) {

            result = ''

          }

          minitableRowData.textRight = result
          minitableRowData.lastAttempt = ''

          if (time <= 0 && !standings[index].playable) minitableRowData.textRight = ''

        }

        if (minitableRowData.textRight !== '') rowsData.push(minitableRowData)
        console.warn(minitableRowData)

      }

    })

    return rowsData

  }

  /**
   * Namapovanie dat pre minitable
   * @param playerInfo - playerInfo pre mapovanie
   * @param playerPoints - body posledneho pokusu hraca
   * @returns MiniTableRowData
   */
  private static getMiniTableDataFromPlayerInfo(playerInfo: PlayerInfoForTable, playerPoints: number) {

    let lastAttempt = `+${ playerPoints}`
    if (playerInfo.uuid !== player.uuid) {

      let index = corePhasesManager.disciplineActualAttempt - 1
      if (disciplinePhasesManager.shootOutAttempt > 0) {

        index = gameConfig.numberOfAttempts - 1

      }

      lastAttempt = `+${playersManager.getPlayerById(playerInfo.uuid)?.resultsArr?.[index]?.main}`

    }

    return {
      position: playerInfo.position,
      country: playerInfo.country,
      countryString: playerInfo.countryString,
      player: {
        name: playerInfo.name,
        isPlayer: playerInfo.playable
      },
      textRight: String(playerInfo.result),
      lastAttempt: corePhasesManager.disciplineActualAttempt === 1 ? '' : lastAttempt
    } as MiniTableRowData

  }

  /**
   * Porobenie veci, ked dobehnu vsetky potrebne requesty
   */
  public afterAllRequestsDone(): void {

    const isWaiting = requestManager.isFirstTrainingTutorial()
    const trainingButtonsDisabled = requestManager.isFirstTrainingTutorial()
    waitingState().$patch({
      isWaiting,
      trainingButtonsDisabled
    })

    // v tutoriali hned presmerujeme
    if (modes.isTutorial()) {

      window.dispatchEvent(new CustomEvent(CustomEvents.finalRedirectDone))
      requestManager.redirect()

    }

    const finalPhase = corePhasesManager.getPhase(CorePhases.finalResults) as PhaseFinalResultsManager
    if (finalPhase) finalPhase.enableSkip()

  }

  /**
   * Resetovanie pinie
   * @param specialExceptions - Specialne vynimky navyse
   */
  public resetPinia(specialExceptions: string[] = []): void {

    const exceptions = ['tutorialState', 'inputsState', 'mobileState', 'loadingState', 'gameSettingsState']
    if (specialExceptions.length > 0) exceptions.push(...specialExceptions)

    // eslint-disable-next-line
    // @ts-ignore
    getActivePinia()?._s.forEach((s: Store) => {

      if (!exceptions.includes(s.$id)) s.$reset()

    })

  }

}

export const stateManager = new StateManager()
