import {
  GunAnimationsNames,
  PlayerAnimationsNames,
  PlayerStates
} from '@/app/types'
import type { Athlete } from './Athlete'
import {
  CallbackAnimationTypes,
  cameraManager,
  modes,
  gsap,
  corePhasesManager,
  CameraStates
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  actionButtonState,
  shellsState,
  uiState
} from '@/stores'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import {
  customCameraConfig,
  pigeonConfig
} from '@/app/config'
import { startPhaseStateManager } from '@/app/phases/StartPhase/StartPhaseStateManager'
import { player } from './player'
import { pigeon } from '../equipment/Pigeon'
import { trainingTasks } from '@/app/modes/training'

/**
 * Dedikovany manazer animacii pre hraca/superov.
 */
export class AthleteAnimationManager {

  /** ci sme uz nastavili podla stavov veci */
  private statesActive = {
    [PlayerStates.prepareIntro]: false,
    [PlayerStates.prepare]: false,
    [PlayerStates.loading]: false,
    [PlayerStates.unloading]: false,
    [PlayerStates.emotion]: false,
    [PlayerStates.stand]: false,
    [PlayerStates.idle]: false,
    [PlayerStates.shoot]: false
  }

  /** aktualna animacia - TODO rozsir o vsetky animacie mimo konecnej */
  public actualAnimation?: PlayerAnimationsNames

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Zmena rychlosti animacii
   * @param speed - Nova rychlost animacii
   */
  private changeAnimationSpeed(speed: number) {

    this.athlete.animationsManager.setSpeed(speed)

  }

  /**
   * Reset rychlosti animacii
   */
  private resetAnimationSpeed() {

    this.athlete.animationsManager.resetSpeed()

  }

  /**
   * Riesenie veci pre loading stav
   * @returns True, ak ide o dany stav
   */
  private isLoadingState(): boolean {

    const isLoading = this.athlete.isState(PlayerStates.loading)
    if (!this.statesActive[PlayerStates.loading] && isLoading) {

      this.athlete.animationsManager.addAnimationCallback(
        PlayerAnimationsNames.loading,
        CallbackAnimationTypes.end,
        () => {

          this.athlete.animationsManager.removeAnimationCallback(
            PlayerAnimationsNames.loading,
            CallbackAnimationTypes.end
          )

          this.statesActive[PlayerStates.loading] = false
          this.athlete.setState(PlayerStates.prepare)

        }
      )

      this.statesActive[PlayerStates.loading] = true
      this.athlete.animationsManager.changeTo(PlayerAnimationsNames.loading)

    }

    return isLoading

  }

  /**
   * Riesenie veci pre unloading stav
   * @returns True, ak ide o dany stav
   */
  private isUnloadingState(): boolean {

    const isUnloading = this.athlete.isState(PlayerStates.unloading)
    if (!this.statesActive[PlayerStates.unloading] && isUnloading) {

      this.athlete.animationsManager.addAnimationCallback(
        PlayerAnimationsNames.unloading,
        CallbackAnimationTypes.end,
        () => {

          this.athlete.animationsManager.removeAnimationCallback(
            PlayerAnimationsNames.unloading,
            CallbackAnimationTypes.end
          )

          gsap.to({}, {
            onComplete: () => {

              if (disciplinePhasesManager.phaseAim.targetHit) {

                tutorialFlow.pigeonHit()

              } else {

                tutorialFlow.pigeonMiss(disciplinePhasesManager.phaseAim.shotsFired)

              }

              const lastAttempt = corePhasesManager.disciplineActualAttempt >= corePhasesManager.disciplineAttemptsCount

              pigeon.stopMoving()
              pigeon.pigeonEnd()
              disciplinePhasesManager.isShootOutNext()
              if (disciplinePhasesManager.isShootOut) {

                disciplinePhasesManager.shootOutAttempt += 1

              }

              startPhaseStateManager.hideButtons()

              if (modes.isTutorial() && (tutorialFlow.pigeonsHit === 1 || lastAttempt)) {

                disciplinePhasesManager.phaseAim.setFinishPhaseTween()
                return

              }

              if (
                disciplinePhasesManager.phaseAim.targetHit &&
                Math.random() <= pigeonConfig.replay.chanceToShow &&
                !lastAttempt
              ) {

                // musime nastavit kameru pre replay az na dalsi frame, aby sa vsetko dobre zobrazilo
                disciplinePhasesManager.phaseAim.changeCameraToReplayNextCameraMove = true
                disciplinePhasesManager.phaseAim.lockedCameraMove = true
                disciplinePhasesManager.phaseAim.replay()

              } else {

                cameraManager.setState(CameraStates.discipline)
                disciplinePhasesManager.phaseAim.resetCameraAndWrapper()
                disciplinePhasesManager.phaseAim.isInEmotion = true
                console.log('IS IN EMOTION !!!!')

                player.changeCameraSettings(
                  customCameraConfig.emotion.idealOffset,
                  customCameraConfig.emotion.idealLookAt,
                  customCameraConfig.emotion.coefSize,
                  customCameraConfig.emotion.changeLerp,
                  true,
                  true,
                  true
                )

                this.athlete.setState(PlayerStates.emotion)
                uiState().$patch({
                  showTimeKeeper: false,
                  showTrainingLayout: modes.isTrainingMode(),
                  isTraining: modes.isTrainingMode()
                })
                shellsState().$patch({
                  shellsLeft: 0,
                  showShells: false
                })
                if (lastAttempt) disciplinePhasesManager.phaseAim.prepareEmotionMessages()

              }

            },
            duration: 0.6
          })


          this.statesActive[PlayerStates.unloading] = false

        }
      )
      this.statesActive[PlayerStates.unloading] = true
      this.athlete.animationsManager.changeTo(PlayerAnimationsNames.unloading)
      this.athlete.gun.animationsManager.changeTo(GunAnimationsNames.gunUnloading)
      actionButtonState().disabled = true

    }

    return isUnloading

  }

  /**
   * Riesenie veci pre shoot stav
   * @returns True, ak ide o dany stav
   */
  private isShootState(): boolean {

    const isShoot = this.athlete.isState(PlayerStates.shoot)
    if (!this.statesActive[PlayerStates.shoot] && isShoot) {

      // this.statesActive[PlayerStates.shoot] = true
      this.athlete.animationsManager.resetActualAnimation()
      this.athlete.animationsManager.changeTo(PlayerAnimationsNames.shoot)
      this.athlete.gun.animationsManager.resetActualAnimation()
      this.athlete.gun.animationsManager.changeTo(GunAnimationsNames.gunShoot)

      // musime dat naspat stav, aby sa to neprehravalo znova a znova
      player.setState(PlayerStates.prepare)

    }

    return isShoot

  }

  /**
   * Riesenie veci pre emotion stav
   * @returns True, ak ide o dany stav
   */
  private isEmotion(): boolean {

    const isEmotion = this.athlete.isState(PlayerStates.emotion)
    if (!this.statesActive[PlayerStates.emotion] && isEmotion) {

      const emotion = disciplinePhasesManager.phaseAim.getEmotion()

      this.athlete.animationsManager.addAnimationCallback(
        emotion,
        CallbackAnimationTypes.end,
        () => {

          this.athlete.animationsManager.removeAnimationCallback(
            emotion,
            CallbackAnimationTypes.end
          )

          if (emotion === PlayerAnimationsNames.happy3) {

            player.animationsManager.crossfadeTo(PlayerAnimationsNames.afterHappy, 0.01, true, false)

          } else if (emotion !== PlayerAnimationsNames.afterEmotion) {

            player.animationsManager.crossfadeTo(PlayerAnimationsNames.afterEmotion, 0.01, true, false)

          }

          // pri poslednom pokuse sa caka inak
          if (
            corePhasesManager.disciplineActualAttempt !== corePhasesManager.disciplineAttemptsCount ||
            disciplinePhasesManager.isShootOut
          ) {

            disciplinePhasesManager.phaseAim.setFinishPhaseTween()

          }

        }
      )

      this.statesActive[PlayerStates.emotion] = true
      console.log(emotion)

      this.athlete.animationsManager.changeTo(emotion)
      const gunEmotionAnimation = [PlayerAnimationsNames.sad, PlayerAnimationsNames.afterEmotion].includes(emotion) ?
        GunAnimationsNames.gunLoadWalk :
        GunAnimationsNames.gunIdleHappy
      this.athlete.gun.animationsManager.changeTo(gunEmotionAnimation)

    }

    return isEmotion

  }

  /**
   * Riesenie veci pre prepare stav
   * @returns True, ak ide o dany stav
   */
  private isPrepareState(): boolean {

    const isPrepare = this.athlete.isState(PlayerStates.prepare)

    if (!this.statesActive[PlayerStates.prepare] && isPrepare) {

      const firstAnimation = disciplinePhasesManager.isFifthAttempt() &&
        (!modes.isTrainingMode() || !trainingTasks.nextAttemptAsRepeat)

      if (!firstAnimation) {

        this.athlete.animationsManager.changeToPaused(PlayerAnimationsNames.prepare)
        this.athlete.animationsManager.manualyUpdateTimeByPercent(PlayerAnimationsNames.prepare, 100)
        this.athlete.gun.animationsManager.changeToPaused(GunAnimationsNames.gunPrepare)
        this.athlete.gun.animationsManager.manualyUpdateTimeByPercent(GunAnimationsNames.gunPrepare, 100)
        disciplinePhasesManager.phaseAim.afterPrepared()

      } else {

        this.athlete.animationsManager.addAnimationCallback(
          PlayerAnimationsNames.prepare,
          CallbackAnimationTypes.end,
          () => {

            this.athlete.animationsManager.removeAnimationCallback(
              PlayerAnimationsNames.prepare,
              CallbackAnimationTypes.end
            )

            disciplinePhasesManager.phaseAim.afterPrepared()

          }
        )

        this.athlete.animationsManager.changeTo(PlayerAnimationsNames.prepare)
        this.athlete.gun.animationsManager.changeTo(GunAnimationsNames.gunPrepare)

      }

      trainingTasks.nextAttemptAsRepeat = false
      this.statesActive[PlayerStates.prepare] = true


    }

    return isPrepare

  }

  /**
   * Riesenie veci pre prepare intro stav
   * @returns True, ak ide o dany stav
   */
  private isPrepareIntroState(): boolean {

    const isPreparingIntro = this.athlete.isState(PlayerStates.prepareIntro)
    if (!this.statesActive[PlayerStates.prepareIntro] && isPreparingIntro) {

      let animation: PlayerAnimationsNames
      if (this.athlete.playable) {

        animation = PlayerAnimationsNames.introHandL

      } else {

        const random = Math.round(Math.random())
        animation = random === 0 ? PlayerAnimationsNames.introClapDown : PlayerAnimationsNames.introClapUp

      }
      this.athlete.gun.animationsManager.changeTo(GunAnimationsNames.gunLoadWalk)
      this.statesActive[PlayerStates.prepareIntro] = true
      this.athlete.animationsManager.resetAnimationTime(animation)
      this.athlete.animationsManager.changeTo(animation)

    }

    return isPreparingIntro

  }

  /**
   * Riesenie veci pre stand stav
   * @returns True, ak ide o dany stav
   */
  private isStandState(): boolean {

    const isStand = this.athlete.isState(PlayerStates.stand)
    if (!this.statesActive[PlayerStates.stand] && isStand) {

      this.athlete.gun.animationsManager.changeTo(GunAnimationsNames.gunLoadWalk)
      this.statesActive[PlayerStates.stand] = true
      this.athlete.animationsManager.resetAnimationTime(PlayerAnimationsNames.stand)
      this.athlete.animationsManager.changeTo(PlayerAnimationsNames.stand)

    }

    return isStand

  }

  /**
   * Riesenie veci pre idle stav
   * @returns True, ak ide o dany stav
   */
  private isIdleState(): boolean {

    const isIdle = this.athlete.isState(PlayerStates.idle)
    if (!this.statesActive[PlayerStates.idle] && isIdle) {

      this.athlete.gun.animationsManager.changeTo(GunAnimationsNames.gunIdleHappy)
      this.statesActive[PlayerStates.idle] = true
      this.athlete.animationsManager.resetAnimationTime(PlayerAnimationsNames.idle)
      this.athlete.animationsManager.changeTo(PlayerAnimationsNames.idle)

    }

    return isIdle

  }

  /**
   * Samotna logika
   */
  private animationLogic(): void {

    this.isEmotion()
    this.isLoadingState()
    this.isPrepareState()
    this.isUnloadingState()
    this.isShootState()
    this.isPrepareIntroState()
    this.isStandState()
    this.isIdleState()

  }

  /**
   * Update metoda volana v move metode velocity manazera
   */
  public update(): void {

    this.animationLogic()

  }

  /**
   * Zresetovanie veci
   */
  public reset(): void {

    this.actualAnimation = undefined
    this.statesActive = {
      [PlayerStates.prepareIntro]: false,
      [PlayerStates.prepare]: false,
      [PlayerStates.loading]: false,
      [PlayerStates.unloading]: false,
      [PlayerStates.emotion]: false,
      [PlayerStates.stand]: false,
      [PlayerStates.idle]: false,
      [PlayerStates.shoot]: false
    }
    this.athlete.gun.animationsManager.changeTo(GunAnimationsNames.gunPrepare)

  }

}
