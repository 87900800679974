import {
  CustomEvents,
  translations,
  CameraStates,
  game,
  modes,
  playersManager,
  trainingManager,
  hints,
  requestManager,
  audioManager,
  cameraManager,
  gsap,
  MobileDetector,
  corePhasesManager
} from '@powerplay/core-minigames'
import {
  actionButtonState,
  blurState,
  instructionsState,
  miniTableState,
  tableState,
  tournamentState,
  trainingResultsState,
  uiState
} from '@/stores'
import {
  AudioGroups,
  AudioNames
} from '@/app/types'
import {
  loadingCircleState,
  loadingState,
  translationsSSM as t,
  tutorialCoreState
} from '@powerplay/core-minigames-ui-ssm'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { aimingDirectionManager } from '@/app/phases/AimPhase/AimingDirectionManager'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  gameConfig,
  shootingConfig,
  translatesReplacements
} from '@/app/config'
import { stateManager } from '@/app/StateManager'
import { endManager } from '@/app/EndManager'
import { timeManager } from '@powerplay/core-minigames'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initiateListeners = (vm: any) => {

  // Listener for language download
  window.addEventListener(CustomEvents.translations, () => {

    if (Number(import.meta.env.VITE_APP_LOCAL)) {

      translations.setTranslations(t, translatesReplacements)

    }

    vm.$i18n.setLocaleMessage('lang', translations.getTranslations())

    // musime zmenit hint v loadingu
    hints.setSpecificHintsCountForDiscipline(3)
    const hint = hints.getHintText(requestManager.disciplineID)

    loadingState().$patch({
      showLoading: true,
      hintText: vm.$t(hint)
    })

  })

  // Listener for game start event
  window.addEventListener(CustomEvents.gameStart, () => {

    if (!modes.isTutorial()) {

      gsap.to(
        {},
        {
          duration: 0.3,
          onComplete: () => {

            if (MobileDetector.isMobile()) uiState().joystickActive = true
            loadingState().showLoading = false

          }
        }
      )

    }

    audioManager.play(AudioNames.audienceQuiet)

  })

  // Listener for game start instruction phase
  window.addEventListener(CustomEvents.startInstructionsPhase, () => {

    console.warn('Camera table')
    instructionsState().$patch({
      showInstructions: true,
      showButton: false
    })
    cameraManager.setState(CameraStates.table)
    cameraManager.playTween(true)
    game.renderScene()

  })

  // Listener pre tutorial Typewrite
  window.addEventListener(CustomEvents.typeWrite, () => {

    if (!modes.isTutorial()) return

    tutorialCoreState().typeWriter = false

  })

  // Listener pre tutorial game event
  window.addEventListener(CustomEvents.tutorialGameEvent, () => {

    if (!modes.isTutorial()) return

    tutorialFlow.checkInput()

  })

  // Listener for game intro start event
  window.addEventListener(CustomEvents.startIntroPhase, () => {

    // audioManager.play(AudioNames.audienceNoise)
    if (modes.isTutorial()) return
    tableState().competitionText = modes.isTrainingMode() ?
      vm.$t('level').replace('%s', trainingManager.level) :
      `tableText${modes.mode}`
    tableState().showTable = true

    if (!modes.isTutorial() && !modes.isTrainingMode()) {

      if (!audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {

        audioManager.play(AudioNames.commentatorIntro)

      }

    }

  })

  // Listener for game start listing phase
  window.addEventListener(CustomEvents.startStartListPhase, () => {

    if (modes.isTutorial()) return

    blurState().$patch({
      isActive: true,
      isTable: true
    })

    const data = playersManager.getDataForTable()
    console.log(data)
    tableState().$patch({
      dataTable: data,
      resultText: 'startList',
      isStartList: true,
      activeState: true
    })

  })

  // Listener for game start discipline phase
  window.addEventListener(CustomEvents.startDisciplinePhase, () => {

    if (modes.isTutorial()) return

    blurState().$patch({
      isActive: false,
      isTable: false
    })

    tableState().$patch({
      showTable: false,
      isStartList: false,
      activeState: false
    })

    // v turnajoch si musime vynulovat vysledky pre hraca, lebo mohol nejake uz mat, ale iba na zaciatku
    if (modes.isTournament() && playersManager.players[0].resultsArr && endManager.resultsCount === 0) {

      for (let i = 0; i < 10; i += 1) {

        playersManager.players[0].resultsArr[i] = { main: 0 }

      }

    }

  })

  // Listener for game start provisional results phase
  window.addEventListener(CustomEvents.startProvisionalResultsPhase, () => {

    if (modes.isTutorial()) return

    disciplinePhasesManager.phaseAim.unlockPointer()
    blurState().$patch({
      isActive: true,
      isTable: true
    })

    playersManager.setStandings(
      2,
      modes.isTournament() && corePhasesManager.disciplineActualAttempt <= 1,
      modes.isTournament() && corePhasesManager.disciplineActualAttempt <= 1
    )

    const dataStandings = playersManager.getStandings()
    console.log(dataStandings)
    // musime si zaznacit najlepsi vysledok, aby sme to potom mohli zobrazit
    tournamentState().bestResult = dataStandings[0].result ?? '-'
    if (tournamentState().bestResult === '') tournamentState().bestResult = '-'

    if (disciplinePhasesManager.isShootOut) {

      dataStandings.forEach((athlete) => {

        if (athlete.results) {

          athlete.results[0] = ''
          // remove rest of the array
          athlete.results.splice(2)
          let result1 = ''
          let result2 = ''
          let time = 0
          const third = athlete.resultsFull?.[gameConfig.numberOfAttempts - 1]?.third
          const second = athlete.resultsFull?.[gameConfig.numberOfAttempts - 1]?.second

          if (disciplinePhasesManager.shootOutOpponents.includes(athlete.uuid) || athlete.playable) {

            result2 = vm.$t('shootout')
            time = shootingConfig.shootOutTimeBase - Number(third)


          } else {

            if (third !== undefined && Number(second) === disciplinePhasesManager.shootOutAttempt - 1) {


              time = shootingConfig.shootOutTimeBase - Number(third)

            }


          }
          console.log(athlete.uuid, time)
          if (time > 0) {

            result1 = time >= shootingConfig.shootOutTimeBase ?
              'MISS' :
              timeManager.getTimeInFormatFromSeconds(time)

          }
          athlete.results = [result2, result1]

        }

      })

    }

    tableState().$patch({
      dataTable: dataStandings,
      resultText: 'provisionalResults',
      showTable: true,
      activeState: true
    })

    actionButtonState().$patch({
      showJoystick: false,
      isShoot: false
    })

    miniTableState().$patch({
      rowsData: [],
      show: false
    })

    // pre turnaje v prvej tabulke nesmieme riesit zvuky
    if (corePhasesManager.disciplineActualAttempt > 1) {

      audioManager.stopAllAudio()
      if (!audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {


        audioManager.play(AudioNames.commentatorProvisionalStandings)

      }

    }

    audioManager.resetSpriteCache(AudioNames.commentatorHitOneShot)
    audioManager.resetSpriteCache(AudioNames.commentatorHitTwoShot)
    audioManager.resetSpriteCache(AudioNames.commentatorMiss)

    // force unlock
    disciplinePhasesManager.phaseAim.isLocked = true
    disciplinePhasesManager.phaseAim.unlockPointer()

  })

  // Listener for game start final standings phase
  window.addEventListener(CustomEvents.startFinalStandingsPhase, () => {

    disciplinePhasesManager.phaseAim.unlockPointer()

    if (modes.isTutorial()) return

    if (!audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {

      audioManager.play(AudioNames.commentatorFinalResults)

    }

    if (modes.isTournament() && !disciplinePhasesManager.tournamentDataSet) return

    blurState().$patch({
      isActive: true,
      isTable: true
    })

    if (modes.isTournament()) playersManager.setStandings()

    const dataTable = playersManager.getStandings()
    console.log(dataTable)
    if (disciplinePhasesManager.shootOutAttempt > 0) {

      dataTable.forEach((athlete) => {

        console.warn(athlete.results)
        if (athlete.results) {

          let time = 0
          const third = athlete.resultsFull?.[gameConfig.numberOfAttempts - 1]?.third
          const second = athlete.resultsFull?.[gameConfig.numberOfAttempts - 1]?.second
          if (third !== undefined && Number(second) === disciplinePhasesManager.shootOutAttempt) {

            time = shootingConfig.shootOutTimeBase - Number(third)

          }
          console.log(second, disciplinePhasesManager.shootOutAttempt)
          let result = ''
          if (time > 0) {

            result = time >= shootingConfig.shootOutTimeBase ?
              'MISS' :
              timeManager.getTimeInFormatFromSeconds(time)

          }
          athlete.results = [result]

        }

      })

    }
    tableState().$patch({
      competitionText: modes.isTrainingMode() ?
        vm.$t('level').replace('%s', trainingManager.level) :
        `tableText${modes.mode}`,
      dataTable,
      resultText: modes.isTournament() || modes.isDailyLeague() ? 'provisionalResults' : 'finalStandings',
      showTable: true,
      activeState: true
    })

    miniTableState().$patch({
      rowsData: [],
      show: false
    })

    actionButtonState().$patch({
      showJoystick: false,
      isShoot: false
    })

    // force unlock
    disciplinePhasesManager.phaseAim.isLocked = true
    disciplinePhasesManager.phaseAim.unlockPointer()

  })

  // Listener for game start final training phase
  window.addEventListener(CustomEvents.startFinalTrainingPhase, () => {

    if (modes.isTutorial()) return
    trainingResultsState().showResults = true

    // audioManager.stopAllAudio()
    // audioManager.play(AudioNames.audienceNoise, undefined, undefined, 1)

  })

  // Listener pre to, aby sme vedeli, ze su vsetky konecne requesty zbehnute
  window.addEventListener(CustomEvents.allRequestDoneOnEnd, () => {

    if (modes.isTournament() && Number(import.meta.env.VITE_APP_LOCAL) === 1) return

    stateManager.afterAllRequestsDone()

  })

  /** Listener pre zobrazenie loading circle po kliknuti na final redirect */
  window.addEventListener(CustomEvents.finalRedirectDone, () => {

    console.log('mal by ist redirect loading')
    loadingCircleState().isActive = true

  })

  window.addEventListener('mousemove', (e: MouseEvent) => {

    if (disciplinePhasesManager.phaseAim?.shootOutFreeze) return
    if (disciplinePhasesManager.phaseAim && disciplinePhasesManager.phaseAim.isLocked) {

      aimingDirectionManager.moveAimingPoint(
        e.movementX * aimingDirectionManager.mouseStep.x,
        -e.movementY * aimingDirectionManager.mouseStep.y,
        true
      )

    } else {

      aimingDirectionManager.actualMousePosition.set(e.clientX, e.clientY)

    }

  })

}
