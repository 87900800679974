import {
  pigeonConfig,
  shootingConfig,
  pigeonShotSmokeConfig,
  gameConfig
} from '@/app/config'
import {
  THREE,
  game,
  type CannonNamedBody,
  CANNON,
  type CollisionEvent,
  audioManager,
  corePhasesManager,
  modes,
  gsap,
  minigameConfig,
  playersManager
} from '@powerplay/core-minigames'
import { player } from '../athlete/player'
import {
  AudioNames,
  ParticleNames
} from '@/app/types'
import { worldEnv } from '../env/WorldEnv'
import { windState } from '@/stores'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { trainingTasks } from '@/app/modes/training'
import { trailManager } from '@/app/TrailManager'
import { particleEffects } from '@/app/ParticleEffects'
import { startPhaseStateManager } from '@/app/phases/StartPhase/StartPhaseStateManager'

/**
 * Trieda pre holuba
 */
export class Pigeon {

  /** Hlavna kost holuba */
  private mainBone = new THREE.Bone()

  /** Group letiaceho holuba */
  private groupFlight = new THREE.Group()

  /** Fyzicke body pre objekt */
  private physicsBody!: CannonNamedBody

  /** ci uz dopadol na zem */
  public hitGround = false

  /** Ci uz sa vystrelilo velakrat na to, aby sa uz nedalo trafit */
  public tooManyMisses = false

  /** 3d objekt holuba pre detekciu zasahu */
  public pigeonBody = new THREE.Mesh()

  /** Glow pre holuba */
  private pigeonGlow = new THREE.Mesh()

  /** Mesh holuba */
  private pigeonMesh!: THREE.Mesh

  /** Ci mame zobrazit glow na holubovi najblizsi frame */
  private showPigeonGlowNextFrame = false

  /** ci holub leti */
  public pigeonFlying = false

  /** vektor rychlosti */
  private velocityVec = new THREE.Vector3()

  /** posledna rychlost vystrelenia holuba */
  private lastSpeed = 0

  /** posledny uhol X vystrelenia holuba */
  private lastAngleX = 0

  /** posledny uhol Y vystrelenia holuba */
  private lastAngleY = 0

  /** fragmenty holuba pre efekt rozbitia */
  public fragmentsMesh!: THREE.Mesh

  /** Ci je aktivna animacia rozbitia terca */
  private pigeonBreakActive = false

  /** Pozicie holuba pre replay */
  public pigeonPositionsReplay: THREE.Vector3[] = []

  /** index pre aktualny krok holuba pri replay */
  private flightManagerVector = new THREE.Vector3()

  /** aktualna rychlost pre hybanie fragmentov pri ich animacii */
  private worldPidgeonPosition = new THREE.Vector3()

  /** Pomocny vector */
  private vectorForWorldSet = new THREE.Vector3()

  /** Ci uz bol releasnuty holub pre replay */
  private releasedReplayPigeon = false

  /** index pre aktualny kroku holuba pri replay */
  private pigeonPositionsReplayIndex = 0

  /** aktualna rychlost pre hybanie fragmentov pri ich animacii */
  private actualVelocityFragmentsReplay = new THREE.Vector3()

  /** aktualna rychlost pre efekt prachu po zostreleni holuba v replayi */
  private actualVelocitySmokeEffectReplay = new THREE.Vector3()

  /** aktualna rychlost pre holuba a kameru v replayi */
  private actualVelocityPigeonCameraReplay = new THREE.Vector3()

  /** Tween pre rozbitie holuba */
  private tweenPigeonBreak?: gsap.core.Tween

  /** Pocitadlo frameov po rozbiti holuba */
  private framesCounterAfterPigeonBreak = 0

  /** Pomocny objekt pre tween */
  private tweenPigeonBreakObject = { value: 0 }

  /** Ci je dostupne dynamicke zmensovanie terca */
  private dynamicReductionShapeSizeActive = false

  /** Pocet trafeni holuba v prvom kole */
  private pigeonHitsAfterFirstRound = 0

  /**
   * Vytvorenie holuba
   */
  public create(): void {

    // this.groupFlight = game.scene.getObjectByName('pigeon') as THREE.Group
    this.groupFlight = new THREE.Group()
    game.scene.add(this.groupFlight)

    this.pigeonMesh = game.scene.getObjectByName('envDynamic_Target') as THREE.Mesh
    this.pigeonMesh.castShadow = true
    this.groupFlight.add(this.pigeonMesh)

    // fragmenty pre animacie
    this.fragmentsMesh = game.getMesh('envDynamic_TargetFragments')
    this.fragmentsMesh.position.set(0, 3, 5)
    this.fragmentsMesh.scale.set(0.01, 0.01, 0.01)
    this.fragmentsMesh.matrixAutoUpdate = true
    this.fragmentsMesh.visible = false
    this.fragmentsMesh.castShadow = true

    const shape = new CANNON.Sphere(pigeonConfig.shapeSize.mainValues[0]) // pre fyziku davame najvacsi mozny size
    this.physicsBody = new CANNON.Body({
      mass: 1,
      material: new CANNON.Material(),
      shape
    }) as CannonNamedBody
    const geometry = new THREE.SphereGeometry(this.getShapeSize())
    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(0xFFFF00)
    })
    this.physicsBody.name = 'physics_pigeon'
    this.physicsBody.type = CANNON.BODY_TYPES.STATIC
    game.physics.addBody(this.physicsBody)

    this.physicsBody.addEventListener('collide', (e: CollisionEvent) => {

      console.log(e.body.name)
      this.pigeonEnd(true)
      startPhaseStateManager.hideButtons()
      // ak sme mali miss, tak nechceme nic z toho nizsie :D
      if (this.tooManyMisses) return
      disciplinePhasesManager.phaseAim.showMiniTable(0)
      disciplinePhasesManager.phaseAim.setReticleVisible(false)

    })
    this.pigeonBody = new THREE.Mesh(geometry, material)
    this.pigeonBody.visible = false
    this.pigeonBody.name = 'pigeon_body'
    game.scene.add(this.pigeonBody)

    this.pigeonGlow = game.getMesh('envDynamic_TargetGlow') as THREE.Mesh
    this.groupFlight.add(this.pigeonGlow)
    this.pigeonGlow.scale.set(0.1, 0.1, 0.1)
    this.pigeonGlow.updateMatrix()
    this.pigeonGlow.renderOrder = 1
    this.pigeonGlow.visible = false

    trailManager.setUp(this.pigeonBody)

  }

  /**
   * ZIstenie a vratenie velkosti holuba
   * @returns Velkost holuba
   */
  private getShapeSize(): number {

    const { mainValues, strengthDiffs, subValues, limitForDynamicReduction } = pigeonConfig.shapeSize
    const playerStrength = player.getAthleteAttributeStrength()
    let indexShapeSize = playerStrength <= 100 ? 0 : 1
    if (modes.isTournament()) indexShapeSize = 2
    let shapeSize = mainValues[indexShapeSize]

    if (!modes.isTrainingMode() && !modes.isTutorial()) {

      const strengthDiffMax = playersManager.getBestAttribute() - playerStrength
      console.log(`strengthDiffMax: ${strengthDiffMax}`)
      let indexOpponentSub = 0
      if (strengthDiffMax > strengthDiffs[1]) {

        indexOpponentSub = 2

      } else if (strengthDiffMax > strengthDiffs[0]) {

        indexOpponentSub = 1

      }
      if (strengthDiffMax > limitForDynamicReduction) {

        this.dynamicReductionShapeSizeActive = true

      }

      shapeSize -= subValues[indexOpponentSub]

    }

    console.log(`ZAKLADNY shapeSize: ${shapeSize}, moznost znizovania? ${this.dynamicReductionShapeSizeActive}`)

    return shapeSize

  }

  /**
   * Znizovanie velkosti holuba po zasahu
   */
  private reduceShapeSizeAfterHit(): void {

    const { firstRoundHits, subValueSecondRound } = pigeonConfig.shapeSize

    // musime to mat povolene a hrac musi trafit x tercov v prvom kole
    if (!this.dynamicReductionShapeSizeActive || this.pigeonHitsAfterFirstRound < firstRoundHits) return

    const scale = this.pigeonBody.scale.x - subValueSecondRound
    this.pigeonBody.scale.set(scale, scale, scale)

    console.log(`NASTALO ZNIZENIE velkosti holuba, momentalny scale je ${scale}`)

  }

  /**
   * Stav po dopade na zem
   * @param groundCollision - Ci islo o koliziu so zemou
   */
  public pigeonEnd(groundCollision = false): void {

    if (this.hitGround) return

    this.hitGround = true
    console.log(`HIT GROUND ON: x: ${this.physicsBody.position.x} z: ${this.physicsBody.position.z}`)

    // uz nepotrebujeme fyziku
    game.togglePhysics(false)

    // vyhodime ho nad podlahu, nech tam nie je zaseknuty
    this.stopMoving()

    if (groundCollision) {

      if (disciplinePhasesManager.isShootOut) {

        playersManager.editPlayerResultsById(
          player.uuid,
          gameConfig.numberOfAttempts - 1,
          undefined,
          disciplinePhasesManager.shootOutAttempt,
          0
        )
        disciplinePhasesManager.phaseAim.resolveShootOutTimes(0)

      } else {

        playersManager.setPlayerResults(0)

      }

    }
    if (!this.tooManyMisses) disciplinePhasesManager.phaseAim.setUnloading()

    if (!disciplinePhasesManager.phaseAim.targetHit) {


      const shotsLimit = disciplinePhasesManager.isShootOut ?
        shootingConfig.shotsLimitShootout :
        shootingConfig.shotsLimit
      if (
        disciplinePhasesManager.phaseAim.shotsFired < shotsLimit &&
        !disciplinePhasesManager.phaseAim.missCallAudioPlayed
      ) {

        audioManager.play(AudioNames.targetMissCall)
        disciplinePhasesManager.phaseAim.missCallAudioPlayed = true

      }
      if (disciplinePhasesManager.phaseAim.shotsFired === 1) {

        disciplinePhasesManager.phaseAim.playAudioAfterShooting(false)

      }
      disciplinePhasesManager.phaseAim.adjustAttemptScoreForAll()

    }

    if (modes.isTrainingMode() && !disciplinePhasesManager.phaseAim.targetHit) {

      if (trainingTasks.attempts[corePhasesManager.disciplineActualAttempt] === 0) {

        trainingTasks.attempts[corePhasesManager.disciplineActualAttempt] += 1
        trainingTasks.nextAttemptAsRepeat = true
        // musime nastavit, ze sa resetuje faza
        corePhasesManager.resetActualPhase = true

      } else {

        // uz sme mali svoj pokus, takze davame dalsi
        trainingTasks.countShotPoints(0)

      }

    }

    if (
      corePhasesManager.disciplineActualAttempt === corePhasesManager.disciplineAttemptsCount &&
      !modes.isTrainingMode()
    ) {

      gsap.to({}, {
        onComplete: () => {

          audioManager.play(AudioNames.audienceHyped)

        },
        duration: 0.5
      })

    }

  }

  /**
   * Release pigeon
   * @param position - pociatocna pozicia
   * @param angleX - Uhol pod akym bude letiet holub horizontalne
   * @param angleY - uhol pod akym bude letiet holub vertikalne
   * @param speed - rychlost holuba
   */
  public releasePigeon(position: CANNON.Vec3 | THREE.Vector3, angleX: number, angleY: number, speed: number) {

    let finalSpeed = speed
    let finalAngleX = angleX
    let finalAngleY = angleY

    // pri druhom pokuse davame predosle
    if (modes.isTrainingMode() && trainingTasks.attempts[corePhasesManager.disciplineActualAttempt] > 0) {

      finalSpeed = this.lastSpeed
      finalAngleX = this.lastAngleX
      finalAngleY = this.lastAngleY

    }

    const { scale, fragmentsScale } = pigeonConfig
    const scaleFragments = fragmentsScale.normal
    const scalePigeon = scale.normal

    this.fragmentsMesh.scale.set(scaleFragments, scaleFragments, scaleFragments)
    this.groupFlight.scale.set(scalePigeon, scalePigeon, scalePigeon)

    console.log('rychlost:', finalSpeed)
    console.log('vertikalny uhol:', finalAngleX)
    console.log('horizontalny uhol:', finalAngleY)

    tutorialFlow.pigeonReleased += 1
    windState().showWind = false

    // vymazeme predosle pozicie
    this.pigeonPositionsReplay = []
    this.pigeonPositionsReplayIndex = 0

    this.pigeonBody.visible = shootingConfig.debugShot

    this.physicsBody.position.set(position.x, position.y, position.z)
    this.physicsBody.type = CANNON.BODY_TYPES.DYNAMIC

    const angle = THREE.MathUtils.degToRad(finalAngleX)
    const velocity = this.velocityVec.set(
      Math.sin(angle),
      THREE.MathUtils.degToRad(finalAngleY),
      Math.cos(angle)
    )

    velocity.multiplyScalar(finalSpeed)

    this.physicsBody.velocity.set(velocity.x, velocity.y, velocity.z)
    game.togglePhysics(true)
    this.update()

    this.groupFlight.visible = true


    // musime dat az za chvilku, aby nebolo vidno vysvietenie este na starej pozicii
    this.showPigeonGlowNextFrame = true
    this.pigeonFlying = true

    // nakoniec si ulozime posledne hodnoty, aby sme ich vedeli nastavit v pripade opakovania v tg
    this.lastSpeed = finalSpeed
    this.lastAngleX = finalAngleX
    this.lastAngleY = finalAngleY

    trailManager.setActive(true)

  }

  /**
   * Releasnutie holuba pre replay
   */
  public releasePigeonReplay(): void {

    // index nastavim tak, aby bolo iba poslednych x pozicii
    this.pigeonPositionsReplayIndex = this.pigeonPositionsReplay.length - 1 - pigeonConfig.replay.frames
    // davame namenej index 1, aby kamera isla dobre a nebol problem pri nultom indexe nahodou
    if (this.pigeonPositionsReplayIndex < 1) this.pigeonPositionsReplayIndex = 1

    this.releasedReplayPigeon = true

    const { scale, fragmentsScale } = pigeonConfig
    const scaleFragments = fragmentsScale.replay
    const scalePigeon = scale.replay

    this.fragmentsMesh.scale.set(scaleFragments, scaleFragments, scaleFragments)
    this.groupFlight.scale.set(scalePigeon, scalePigeon, scalePigeon)

    this.groupFlight.visible = true
    this.pigeonBody.visible = shootingConfig.debugShot
    this.pigeonFlying = true

  }

  /**
   * Nastavenie pozicie
   * @param position - Pozicia
   */
  public setFlightPosition(position: THREE.Vector3): void {

    this.groupFlight.position.set(position.x, position.y, position.z)
    this.fragmentsMesh.position.set(position.x, position.y, position.z)
    this.pigeonBody.position.set(position.x, position.y, position.z)

    if (this.groupFlight.visible && this.pigeonFlying) {

      this.pigeonPositionsReplay.push(position.clone())

    }

    // ak sa bugol pod zemou - nebola detekovana kolizia
    if (!this.tooManyMisses && this.groupFlight.position.y < (worldEnv.floorPhysicsBody?.position.y || 0)) {

      this.pigeonEnd()

      disciplinePhasesManager.phaseAim.showMiniTable(0)

    }

    this.pigeonGlow.lookAt(player.getPosition())
    this.pigeonGlow.updateMatrix()

    if (this.showPigeonGlowNextFrame) {

      this.showPigeonGlowNextFrame = false
      this.pigeonGlow.visible = true

    }

  }

  /**
   * Zaciatok animacie rozbitia holuba
   */
  public startAnimationPigeonBreak(): void {

    this.fragmentsMesh.rotation.set(0, 0, 0)
    this.pigeonBreakActive = true
    this.fragmentsMesh.visible = true
    this.tweenPigeonBreakObject = { value: 0 }
    this.tweenPigeonBreak = gsap.to(
      this.tweenPigeonBreakObject,
      {
        value: 1,
        duration: pigeonConfig.replay.durationPigeonBreak,
        ease: pigeonConfig.replay.easePigeonBreak
      }
    ).pause()

  }

  /**
   * Animacia rozbitia holuba
   */
  private updateAnimationPigeonBreak(): void {

    // if (!this.fragmentsMesh.morphTargetInfluences || !this.pigeonBreakActive) return

    if (this.fragmentsMesh.morphTargetInfluences) {

      const framesTotal = pigeonConfig.replay.durationPigeonBreak * minigameConfig.fpsLimit
      const percent = this.framesCounterAfterPigeonBreak / framesTotal
      if (percent >= 1) {

        this.pigeonBreakActive = false
        this.fragmentsMesh.visible = false

      } else {

        this.tweenPigeonBreak?.progress(percent)
        this.fragmentsMesh.morphTargetInfluences[0] = this.tweenPigeonBreakObject.value

      }


    }

  }

  /**
   * Aktualizacia holuba pocas replayu
   */
  private replayUpdate(): void {

    const actualPosition = this.pigeonPositionsReplay[this.pigeonPositionsReplayIndex]
    if (actualPosition === undefined) {

      // uz sme na konci, takze davame hit, ale len raz
      if (!this.hitGround) {

        this.pigeonHit()
        audioManager.play(AudioNames.targetHit)
        this.groupFlight.visible = false
        this.pigeonBody.visible = false
        this.pigeonFlying = false

      }

      // musime zmenit velocity pre rozne veci, ktore sa riesia v animacnom update
      if (this.pigeonBreakActive) {

        const { coefCameraSlowDown, coefFragmentsSlowDown, coefGravitation } = pigeonConfig.replay

        // pohyb kamery
        this.actualVelocityPigeonCameraReplay = this.actualVelocityPigeonCameraReplay.multiplyScalar(coefCameraSlowDown)

        this.framesCounterAfterPigeonBreak += 1

        // pohyb fragmentov
        this.actualVelocityFragmentsReplay = this.actualVelocityFragmentsReplay.multiplyScalar(coefFragmentsSlowDown)
        this.actualVelocityFragmentsReplay.y -= (coefGravitation * this.framesCounterAfterPigeonBreak)

        this.updateAnimationPigeonBreak()
        this.updateVelocities(1 / minigameConfig.fpsLimit)

      }

      return

    }

    disciplinePhasesManager.phaseAim.lockedCameraMove = false

    // menime poziciu
    this.groupFlight.position.set(actualPosition.x, actualPosition.y, actualPosition.z)
    this.fragmentsMesh.position.set(actualPosition.x, actualPosition.y, actualPosition.z)
    this.pigeonBody.position.set(actualPosition.x, actualPosition.y, actualPosition.z)

    // tiene
    if (this.fragmentsMesh) {

      const worldPigeonPosition = this.fragmentsMesh.getWorldPosition(this.worldPidgeonPosition)
      worldEnv.manageShadowsPosition(this.vectorForWorldSet.set(worldPigeonPosition.x, 0.1, worldPigeonPosition.z))

    }

    this.pigeonPositionsReplayIndex += 1

  }

  /**
   * Aktualizovanie holuba
   */
  public update(): void {

    if (disciplinePhasesManager.phaseAim.isReplay && this.releasedReplayPigeon) {

      this.replayUpdate()
      return

    }

    this.setFlightPosition(this.flightManagerVector.set(
      this.physicsBody.position.x,
      this.physicsBody.position.y,
      this.physicsBody.position.z
    ))

    const { decelerationCoef } = shootingConfig.pigeon.speed
    this.physicsBody.velocity.x *= decelerationCoef
    this.physicsBody.velocity.y *= decelerationCoef
    this.physicsBody.velocity.z *= decelerationCoef
    trailManager.update()

  }

  /**
   * Aktualizovanie velocity pre rozne objekty
   * @param delta - Delta casu
   */
  private updateVelocities(delta: number): void {

    // robime veci az po rozbiti holuba
    if (this.pigeonPositionsReplay[this.pigeonPositionsReplayIndex] !== undefined || !this.pigeonBreakActive) return

    // hybanie ulomkov po krivke podla poslednej velocity
    this.fragmentsMesh.position.add(this.actualVelocityFragmentsReplay.clone().multiplyScalar(delta))

    // hybanie holuba po krivke podla poslednej velocity, pricom spomalujeme, kvoli kamere
    const newVelocityCameraPigeon = this.actualVelocityPigeonCameraReplay.clone().multiplyScalar(delta)
    this.pigeonBody.position.add(newVelocityCameraPigeon)

  }

  /**
   * Zobrazenie particle efektu zasahu terca
   * @param isReplay - Ci ide o replay partikle
   */
  private showParticlesEffectAfterPigeonHit(isReplay: boolean): void {

    const pos = this.pigeonBody.position.clone()
      .sub(pigeonShotSmokeConfig.particlesConfig.particleSpan.clone().multiplyScalar(0.5))

    let velocity = pigeonShotSmokeConfig.velocityOriginal.clone()
    let velocitySlowDown = 0
    if (isReplay) {

      velocitySlowDown = pigeonConfig.replay.coefSmokeSlowDown
      velocity = this.actualVelocitySmokeEffectReplay.clone()


    }
    const smokeEmitter = particleEffects.getEmitter(ParticleNames.pigeonShotSmoke)
    if (smokeEmitter) {

      smokeEmitter.setVelocity(velocity)
      smokeEmitter.setVelocitySlowDown(velocitySlowDown)

    }

    particleEffects.startEmitter(ParticleNames.pigeonShotSmoke, 1, pos)

  }

  /**
   * Zastavenie holuba
   */
  public stopMoving(): void {

    this.pigeonFlying = false
    this.physicsBody.velocity.set(0, 0, 0)

    this.groupFlight.visible = false
    this.pigeonBody.visible = false
    this.pigeonGlow.visible = false
    trailManager.setActive(false)
    trailManager.reset()

    this.physicsBody.type = CANNON.BODY_TYPES.STATIC
    this.physicsBody.position.y = 100
    this.groupFlight.position.y = 100
    this.physicsBody.velocity.set(0, 0, 0)

  }

  /**
   * Holub zasiahnuty
   */
  public pigeonHit(): void {

    this.showParticlesEffectAfterPigeonHit(disciplinePhasesManager.phaseAim.isReplay)
    this.groupFlight.visible = false

    if (disciplinePhasesManager.phaseAim.isReplay) {

      this.startAnimationPigeonBreak()

      // aby nesiel pigeonEnd
      this.hitGround = true
      disciplinePhasesManager.phaseAim.setFinishPhaseTween()

    } else {

      // musime si zaznamenat velocity aka bola a potom s nou budeme pracovat v replayi
      this.actualVelocityFragmentsReplay.set(
        this.physicsBody.velocity.x,
        this.physicsBody.velocity.y,
        this.physicsBody.velocity.z
      )
      this.actualVelocityPigeonCameraReplay.copy(this.actualVelocityFragmentsReplay)
      this.actualVelocitySmokeEffectReplay.copy(this.actualVelocityFragmentsReplay)

      this.pigeonEnd()

    }

    if (!modes.isTutorial() && !disciplinePhasesManager.phaseAim.isReplay) {

      if (corePhasesManager.disciplineActualAttempt <= corePhasesManager.provisionalResultsFrequency) {

        this.pigeonHitsAfterFirstRound += 1

      }

      if (corePhasesManager.disciplineActualAttempt >= corePhasesManager.provisionalResultsFrequency) {

        this.reduceShapeSizeAfterHit()

      }

    }

  }

  /**
   * Reset influences pre morph targety
   */
  private resetMorphTargetsInfluences(): void {

    if (this.fragmentsMesh.morphTargetInfluences) {

      for (let i = 0; i < this.fragmentsMesh.morphTargetInfluences.length; i += 1) {

        this.fragmentsMesh.morphTargetInfluences[i] = 0

      }

    }

  }

  /**
   * Zastavenie tweenu pre rozbitie holuba
   */
  public stopTweenPigeonBreak(): void {

    this.tweenPigeonBreak?.kill()

  }

  /**
   * Zastavenie zmensovania holuba
   */
  public stopReducingScale(): void {

    this.pigeonHitsAfterFirstRound = 0

  }

  /**
   * reset holuba
   */
  public reset(): void {

    this.hitGround = false
    this.tooManyMisses = false

    this.pigeonBreakActive = false
    this.fragmentsMesh.visible = false

    this.releasedReplayPigeon = false
    this.pigeonPositionsReplayIndex = 0
    this.framesCounterAfterPigeonBreak = 0

    this.stopTweenPigeonBreak()
    this.resetMorphTargetsInfluences()

  }

}

export const pigeon = new Pigeon()
